import React from 'react';

import BlogArticle from 'components/blog/blogArticle';
import { authorSzymon as author } from 'data/authors';
import Link from 'components/ui/link';
import { Post } from 'components/blog/content/styled';
import cover from 'img/blog/heatmaps-art/heatmap-cover.jpg';
import desk from 'img/blog/heatmaps-art/desk-image.png';
import webdesign from 'img/blog/heatmaps-art/webdesign-image.png';

const Content = () => {
  return (
    <Post>
      <p>
        <strong>
          There are many ways to improve the performance of your website. No matter what aspects you
          wish to change - you should first figure out whether there are any elements that actually
          needs improvement. See how you can use heat maps to identify the issues and improve your
          page performance.
        </strong>
      </p>

      <p>
        Essentially, heat maps visualise how visitors are interacting with your website and its
        elements. It’s possible thanks to dedicated tools, which record and quantify what people do
        with their mouses or trackpads, and then present the collected data in an appealing way. How
        can you use those insights to make improvements on your website, though?{' '}
      </p>

      <h2>Types of heat maps</h2>

      <p>
        Let’s start with the basics. It might not seem like it at first, but heat maps are actually
        a broader category that includes:{' '}
      </p>
      <ul>
        <li>
          <strong>Hover maps </strong>(also called <strong>move maps</strong>
          ), which help you track any mouse-movements on the website. The idea behind it is that
          people tend to look where they hover over a page with their mouse cursor. This should be a
          good enough indication on how they read your website, yet, mouse-cursor tracking might not
          be as accurate as eye-tracking alone. Website visitors might easily be looking elsewhere -
          but it still can give you a lot of insights anyway;{' '}
        </li>
        <li>
          <strong>Click maps</strong>, on the other hand, show you where and how often the web users
          click on certain elements of your site. It’s a great way to optimise internal linking -
          you can see whether your links are being clicked on, or get some new ideas on where to
          place clickable elements;
        </li>
        <li>
          <strong>Scroll maps</strong> work a bit differently, as they show you how far people
          scroll down on a page. It’s worth to keep in mind that users might scroll for a while, but
          their attention span is short. That’s precisely why you should place the most important
          details at the top of your website since they may not make it all the way to the bottom.
        </li>
      </ul>

      <p>
        Speaking of all the ways you can track the behaviour of your web visitors, it’s also worth
        to mention the <strong>user session replays</strong>. They don’t really fall under the “heat
        maps” category - but are as valuable. Basically, apart from tracking all the mouse
        movements, clicks and scrolls, dedicated tools record video sessions of visitors going
        through your site, and allow you to watch and analyse these recordings.
      </p>

      <p>
        Unlike heat maps, this kind of data is qualitative - you can easily see any usability issues
        for yourself. It means, however, that drawing actual conclusions from such videos will
        require some more time and effort from you.
      </p>

      <img
        src={desk}
        className="img-fluid justify-content-center mb-4"
        alt="desk"
        loading="lazy"
        title="Desk"
      />
      <p className="text-center" style={{ margin: '-1rem 0 2rem 0', fontSize: '.7rem' }}>
        Image by{' '}
        <Link rel="noopener nofollow" target="_blank" href="https://rawpixel.com">
          rawpixel.com
        </Link>
      </p>

      <h2>Use heat maps to improve the performance of your site</h2>

      <p>
        No matter the specific type, heat maps can serve as a powerful tool for developers,
        marketing professionals, and customer support agents. They simply provide useful data on the
        performance of your site and its content - by drawing immediate attention to the issues on
        the website and indicating the areas that might need some improvement.
      </p>

      <p>What exactly get you get out of them?</p>

      <h3>1. Heat maps allow you to learn from your users.</h3>

      <p>
        Being aware of how visitors use your site helps you create a better web design. In essence,
        heat maps can help you understand how the visitors use your site (and whether they “land”
        where you want them to). Instead of constantly asking your visitors for feedback or
        encouraging them to take surveys, you can just take a look at the session replays. It’s
        simple as that. Whenever you have a new landing page to test, you can combine A/B testing
        with heat maps and session replays to learn from your users without being intrusive.
      </p>

      <h3>
        2. Heat maps help you understand your visitors and improve their experience with your site.
      </h3>

      <p>
        Learning from your users can also help you understand them better. After all, your website
        should be tailored to both the business goals you want to achieve, as well as the needs of
        your target audience. Whether your goal is to sell a product, increase brand awareness or
        pitch an idea, your visitors automatically become potential customers. That’s precisely why
        you should understand how they react to your content and whether you there’s a need to
        organise it differently. If you’re listening to your audience and learn from them, you can
        easily offer proper user experience in the long run.
      </p>

      <h3>3. Heat maps let you optimise your site in terms of SEO.</h3>

      <p>
        Having your content well-optimised for SEO is a must, but the quality of the website is
        equally important. Once the visitors land on your website, they should be satisfied with the
        results of their search. That’s hardly surprising - Google is always trying to serve the
        best possible results to users, which is why you should, yet again, ensure proper user
        experience. This is much easier with heat maps and session replays, when you know how your
        site is being used, and what might be wrong with it. As a result, you can not only determine
        the ideal content length and its structure, but also improve internal links & navigation, or
        optimise image placement. All of the above factors have an impact on SEO.{' '}
      </p>

      <h3>4. Heat maps can improve conversion rates.</h3>

      <p>
        Heat maps will show you exactly where (and where not) visitors click on the site and what
        attracts their attention. This is especially important when optimising CTAs and their
        placement. Basically, you can use that information to organise your website properly -
        highlighting any CTAs you want, and encouraging certain actions from your visitors. This
        way, you can improve conversion rates more easily.
      </p>

      <img
        src={webdesign}
        className="img-fluid justify-content-center mb-4"
        alt="webdesign"
        loading="lazy"
        title="Design"
      />
      <p className="text-center" style={{ margin: '-1rem 0 2rem 0', fontSize: '.7rem' }}>
        Image by{' '}
        <Link rel="noopener noreferrer nofollow" target="_blank" href="https://rawpixel.com">
          rawpixel.com
        </Link>
      </p>

      <p>
        As you can see, heat maps and user session replays can definitely complement analytical apps
        in your toolkit. The insights they provide are quite unique when comparing them to other
        tools, which is why you should definitely give them a try if you strive to improve your page
        performance.{' '}
      </p>

      <p>
        Once you’re at it, you might want to see{' '}
        <Link rel="noopener noreferrer" target="_blank" href="/">
          LiveSession
        </Link>{' '}
        in action - especially since there’s a free trial available. You’ll immediately learn that
        recording how a user interacts with your website, analyzing their behaviour and drawing
        conclusions from it in order to improve the performance of your site have never been easier
      </p>
    </Post>
  );
};

const Wrapped = BlogArticle(Content);

export const frontmatter = {
  title: 'How to use heat maps to improve your page performance',
  url: '/blog/how-to-use-heat-maps-to-improve-your-page-performance',
  description: '',
  author,
  img: cover,
  imgSocial: cover,
  date: '2019-09-06',
  category: '',
  imgStyle: { borderBottom: '1px solid rgba(0,0,0,0.125)' },
  group: 'Analytics',
};

export default () => (
  <Wrapped
    title={frontmatter.title}
    category={frontmatter.category}
    description={frontmatter.description}
    date={frontmatter.date}
    author={frontmatter.author}
    url={frontmatter.url}
    img={frontmatter.img}
    imgSocial={process.env.GATSBY_HOST + frontmatter.img}
    group={frontmatter.group}
    descriptionSocial="There are many ways to improve the performance of your website. No matter what aspects you wish to change - you should first figure out whether there are any elements that actually needs improvement. See how you can use heat maps to identify the issues and improve your page performance."
  />
);
